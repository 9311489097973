import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { FaSquare } from "react-icons/fa";
import baskan from "../../assets/baskan.png";
import Plans from "../../components/Plans/Plans";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoverItemBalcova, setHoverItemBalcova] = useState(false);
  const [hoverItemKurumsal, setHoverItemKurumsal] = useState(false);
  const [hoverItemHizmetler, setHoverItemHizmetler] = useState(false);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [currentIndex]);

  const slides = [
    {
      url: "https://www.balcova.bel.tr/images/haberler/2024419155352653.jpg",
      text: "Balçova için yorulmadan çalışacağız.",
    },
    {
      url: "https://www.balcova.bel.tr/images/haberler/2024529114030927.jpg",
      text: "İkinci resim açıklaması",
    },
    {
      url: "https://www.balcova.bel.tr/images/haberler/202443015640679.jpg",
      text: "Üçüncü resim açıklaması",
    },
  ];
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div>
      <div className="bg-gradient-to-r from-homeLayoutBackgroundColorBegin to-homeLayoutBackgroundColorFinish flex flex-row justify-center p-4 gap-20">
        <div className="pb-16 mt-7">
        
          <div className="mt-7">
            <img
              src={slides[currentIndex].url}
              className="w-[900px] h-[500px] rounded-2xl object-cover duration-500 mt-7"
              alt="Slide"
            />
            <div className="absolute bottom-[31%] text-white font-semibold text-xl  ml-3 bg-homeImageTextContainer p-2 pr-28 rounded-md w-2/6 opacity-80">
              {slides[currentIndex].text}
            </div>
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div>
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div>
            <div className="flex top-4 justify-start py-2 ">
              {slides.map((slide, slideIndex) => (
                <div
                  key={slideIndex}
                  onClick={() => goToSlide(slideIndex)}
                  className={`text-xl cursor-pointer ${
                    currentIndex === slideIndex ? "text-gray-500" : "text-white"
                  }`}
                >
                  <FaSquare />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-end mb-24">
          <div>
            <img src={baskan} className="w-80 rounded-md" />
          </div>
          <div
            className="relative p-2 rounded-md text-black bg-white text-center"
            onMouseEnter={() => setHoverItemBalcova(true)}
            onMouseLeave={() => setHoverItemBalcova(false)}
          >
            <p className="px-28 text-customNavbarTextColor font-semibold cursor-pointer hover:cursor-pointer">
              Balçova
            </p>
            {hoverItemBalcova && (
              <div className="absolute left-50 mt-2 w-[440px] bg-slate-50 rounded-md shadow-lg z-10">
                {/* <div className="mt-2 border-b-2 p-1 text-lg text-customNavbarTextColor font-semibold border-b-2">
                  Balçova
                </div> */}
                <div className="flex flex-row gap-2 justify-between">
                  <div className="flex flex-col items-start w-full">
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/47/genel-bilgiler"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Genel Bilgiler
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/145/gezilebilicek-yerler"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Gezilebilecek Yerler
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/49/tarihce"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Tarihçe
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/50/nufus-kayit"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Nüfus Kayıt
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/53/piknik-alanlari"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Piknik Alanları
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/54/konaklama"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Konaklama
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/parklarharita/Parklar.aspx"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Parklar Harita
                    </a>
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/55/yuruyus-alanlari"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Yürüyüş Alanları
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/52/su-oyun-parklari"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Su Oyun Parkları
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/56/fotograflarla-balcova"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Fotoğraflarla Balçova
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/balcova360/index.html"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Balçova 360
                    </a>
                    <a
                      href="https://www.izmireczaciodasi.org.tr/nobetci-eczaneler"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Nöbetçi Eczane
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/246/kvkk-kapsaminda-aydinlatma-metni"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      KVKK
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative p-2 rounded-md text-black bg-white text-center"
            onMouseEnter={() => setHoverItemKurumsal(true)}
            onMouseLeave={() => setHoverItemKurumsal(false)}
          >
            <p className="text-customNavbarTextColor font-semibold text-base cursor-pointer">
              Kurumsal
            </p>
            {hoverItemKurumsal && (
              <div className="absolute left-50 mt-2 w-[470px] bg-slate-50 rounded-md shadow-lg z-10">
                {/* <div className="mt-2 border-b-2 p-1 text-lg text-customNavbarTextColor font-semibold border-slate-50">
                  Kurumsal
                </div> */}
                <div className="flex flex-row gap-2 justify-between">
                  <div className="flex flex-col items-start  w-full">
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/277/belediye-baskani-onur-yigit"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Belediye Başkanı
                    </a>
                    <a className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2">
                      Müdürlükler
                    </a>
                    <div className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2">
                      Belediye Meclisi
                    </div>
                    <a
                      href="https://www.balcova.bel.tr/BilgiEdinme.aspx"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Bilgi Edinme
                    </a>
                    <a
                      href=""
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Raporlar
                    </a>
                    <div className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2">
                      Dokümanlar
                    </div>
                  </div>
                  <div className="flex flex-col items-start  w-full">
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/66/kurumsal-logo"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Kurumsal Logo
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/67/organizasyon-semasi"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Organizasyon Şeması
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/77/hesap-iban-numaralari"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Hesap - IBAN No
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/194/etik-komisyonu"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Etik Komisyonu
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/212/arabuluculuk-komisyonu"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Arabuluculuk Komisyonu
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/224/yonergeler"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Yönergeler
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative p-2 rounded-md text-black bg-white text-center"
            onMouseEnter={() => setHoverItemHizmetler(true)}
            onMouseLeave={() => setHoverItemHizmetler(false)}
          >
            <p className="text-customNavbarTextColor font-semibold text-base cursor-pointer">
              Hizmetler
            </p>
            {hoverItemHizmetler && (
              <div className="absolute left-50 mt-2 w-[470px] bg-slate-50 rounded-md shadow-lg z-10">
                {/* <div className="mt-2 border-b-2 p-1 text-lg text-customNavbarTextColor font-semibold border-slate-50">
                  Hizmetler
                </div> */}
                <div className="flex flex-row gap-2 justify-between">
                  <div className="flex flex-col items-start  w-full">
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/167/veteriner-isleri-mudurlugu"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Veteriner Hizmetleri
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/5/cenaze-ve-defin-islemleri"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Cenaze ve Defin İşlemleri
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/6/nikah-islemleri"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Nikah İşlemleri
                    </a>
                  </div>
                  <div className="flex flex-col items-start  w-full">
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/7/asker-ailelerine-yardim"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      Asker Ailelerine Yardım
                    </a>
                    <a
                      href="https://www.balcova.bel.tr/Sayfa/8/e-imar-durum-bilgisi"
                      className="cursor-pointer font-semibold transition hover:text-white hover:bg-[#84acd0] text-sm text-zinc-800 w-full border-b border-zinc-300 p-2"
                    >
                      E-İmar Durum Bilgisi
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className=" p-2 rounded-md text-black bg-white text-center">
            <a
              href="https://www.balcova.bel.tr/Projeler.aspx"
              className="text-customNavbarTextColor font-semibold text-base cursor-pointer"
            >
              Projeler
            </a>
          </div>
          <div className=" p-2 rounded-md text-black bg-white text-center">
            <a
              href="https://www.balcova.bel.tr/ihaleler.aspx"
              className="text-customNavbarTextColor font-semibold text-base cursor-pointer"
            >
              İhaleler
            </a>
          </div>
          <div className=" p-2 rounded-md text-black bg-white text-center">
            <a
              href="https://www.balcova.bel.tr/sayfa/124/duyurular.aspx"
              className="text-customNavbarTextColor font-semibold text-base cursor-pointer"
            >
              Duyurular
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
