import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index";
import Home from "./pages/Home/index";
import Plans from "./components/Plans/Plans";
import Announcements from "./components/Announcements/index";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <div className="flex flex-row justify-center items-start gap-4 bg-slate-100">
        <Plans />
        <Announcements />
      </div>

      <Footer />
    </div>
  );
}

export default App;
