import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Announcements = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const imageInterval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [currentIndex]);
  const slides = [
    {
      url: "https://www.balcova.bel.tr/images/duyurular/2023124102937861.jpg",
      text: "Yerel Eşitlik Eylem Planı",
    },
    {
      url: "https://www.balcova.bel.tr/images/duyurular/20167191612864.jpg",
      text: "İkinci resim açıklaması",
    },
    {
      url: "https://www.balcova.bel.tr/images/duyurular/2023124102937861.jpg",
      text: "Üçüncü resim açıklaması",
    },
  ];
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  return (
    <div className="text-white p-5">
      <div className="flex flex-col gap-3 justify-center items-center">
        <div className="text-2xl mb-4 text-black">Duyurular</div>
        <div className="relative border-2 p-4 flex flex-col items-center justify-around rounded-md w-[600px] min-h-[200px] bg-slate-50">
          <img
            className="w-24 mb-4"
            src={slides[currentIndex].url}
            alt="Slide"
          />

          <div className="text-center text-black">
            {slides[currentIndex].text}
          </div>

          <div
            className="absolute top-1/2 left-0 transform -translate-y-1/2 text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={prevSlide}
          >
            <AiOutlineLeft />
          </div>

          <div
            className="absolute top-1/2 right-0 transform -translate-y-1/2 text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={nextSlide}
          >
            <AiOutlineRight />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Announcements;
