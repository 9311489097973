import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Plans = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const imageInterval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [currentIndex]);
  const slides = [
    {
      text: "İZMİR İLİ, BALÇOVA İLÇESİ, KORUTÜRK VE TELEFERİK MAHALLELERİNDE YER ALAN, İZMİR İNCİRALTI TURİZM MERKEZİ TEVSİİ BALÇOVA KAPLICALARI KESİMİ 1/1000 ÖLÇEKLİ 1. ETAP UYGULAMA İMAR PLANI PLAN NOTU DEĞİŞİKLİĞİ VE İLAVESİ",
    },
    {
      text: "İZMİR İLİ, BALÇOVA İLÇESİ, KORUTÜRK MAHALLESİNDE YER ALAN, İZMİR İNCİRALTI TURİZM MERKEZİ TEVSİİ BALÇOVA KAPLICALARI KESİMİ 1/1000 ÖLÇEKLİ 2. ETAP UYGULAMA İMAR PLANI PLAN NOTU DEĞİŞİKLİĞİ VE İLAVESİ",
    },
  ];
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  return (
    <div className="text-white p-5">
      <div className="flex flex-col gap-3 justify-center items-center">
        <div className="text-2xl mb-4 text-black">Askıda İmar Planları</div>
        <div className="relative border-2 p-4 flex items-center justify-between rounded-md w-[600px] min-h-[200px] bg-slate-50">
          <div
            className="absolute left-0 text-2xl cursor-pointer text-gray-500 hover:text-gray-700 z-10 p-2"
            onClick={prevSlide}
          >
            <AiOutlineLeft />
          </div>

          <div className="text-center text-black w-full px-8">
            {slides[currentIndex].text}
          </div>

          <div
            className="absolute right-0 text-2xl cursor-pointer text-gray-500 hover:text-gray-700 z-10 p-2"
            onClick={nextSlide}
          >
            <AiOutlineRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
